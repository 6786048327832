import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import { IconHandFinger, IconMouse, IconPointer } from "@tabler/icons";

interface Image {
  datetime: string;
  id: string;
}

async function loadImages() {
  const response = await fetch("images");
  return (await response.json()) as Image[];
}

function isTouchDevice() {
  return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
}

let loadTime = 0;

export default function App() {
  const [firstLoad, setFirstLoad] = useState(true);
  const [images, setImages] = useState<Image[]>([]);
  const [active, setActive] = useState(0);
  const [guideVisible, setGuideVisible] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      loadTime = new Date().getTime();
      loadImages()
        .then((images) => setImages(images))
        .catch(console.error);

      window.setTimeout(() => setGuideVisible(false), 5000);
    }

    setFirstLoad(false);
  });

  const count = images.length;
  const datetime = images[active]?.datetime;
  const day = datetime && format(new Date(datetime), 'E').toUpperCase();
  const time = datetime && format(new Date(datetime), 'haaa');
  
  const bandWidth = count > 0 ? window.innerWidth / count : 0;

  function swapImage(evt: React.JSX.TargetedPointerEvent<HTMLDivElement>) {
    if (bandWidth > 0) {
      setActive(Math.floor(evt.x / bandWidth));
      setGuideVisible(false);
    }
    evt.preventDefault();
  }

  return (
    images.length > 0 && (
      <>
        <div
          class="image"
          style={{
            backgroundImage: `url(images/${images[active].id})`,
          }}
          onPointerMove={swapImage}
        />
        
        <div class="time">
          <div>{day}</div>
          <div>{time}</div>
        </div>
        
        {/* Preload images */}
        {images.map((img) => (
          <img src={`images/${img.id}`} style={{ display: "none" }} />
        ))}

        {guideVisible &&
          <div id="guide">
            {isTouchDevice() 
              ? <IconHandFinger color="white" size={50} />
              : <IconPointer color="white" size={50} />}
          </div>
        }
      </>
    )
  );
}
